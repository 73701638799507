export const environment = {
  apiUrl: 'https://api.demo.28one-dev.com',
  ENV: 'dev',
  sentry: {"enable":false,"enableReplayIntegration":false,"enableBrowserTracingIntegration":false},
  dataName: 'general',
  clientName: 'demo',
  enableReset: false,
  production: false,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://demo.28one-dev.com',
  navBar: {
    leftImage: 'assets/svg/logo-dark.svg',
    leftImageHeight: 30,
    menuSetting: '[{"name":"reports","default":true,"page-name":"ReportSection08cd1c51b0ba529b769c","report-id":"1b414d40-fef0-4e72-914a-9827293011de"},{"name":"dashboards","default":false,"page-name":"ReportSection08cd1c51b0ba529b769c","report-id":"1b414d40-fef0-4e72-914a-9827293011de"},{"name":"inspection","default":false}]',
    roleFilter: '{}',
    showAdmin: false,
    asDropdown: false,
  },
  sideBar: '{"inspection-plan-title":"AQL Inspection Plan","inspections-icon":"t-shirt.svg","show-po-selection":false,"show-packaging":true,"show-downloads":true}',
  import: '{"select-group":false,"title":"UPLOAD SUPPLIER BOOKING DETAILS","select-centre":true,"select-inspection-level":false,"select-inspection-type":false}',
  uploadSizeChart: '{"add-title":"ADD STYLE","need-upload":true,"title":"UPLOAD SIZE CHART","delete-title":"DELETE STYLE CODE"}',
  clientInspection: '{"consol-filter":"Consol. Centre","table":[{"name":"Insp. Date","key":"inspection_date","case":"name"},{"name":"Loc.","type":"number","key":"UDC_id","case":"normal"},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal"},{"name":"Supplier Name","key":"supplier_name","case":"name"},{"name":"Season","key":"season_code","case":"normal"},{"name":"PO Numbers","key":"client_pos","case":"normal"},{"name":"Style","key":"style_code","case":"normal"},{"name":"Description","key":"style_name","case":"name"},{"name":"Colour","key":"colour","case":"name"},{"name":"Result","key":"result","case":"result"},{"name":"Full Report","type":"pdf","case":"file"},{"name":"Summary Report","type":"pdf","case":"summary_file"}],"date-range-minus":1,"department-list":"","division-filter":"","colour-filter":"Colour","result-filter":"Result"}',
  selectInspection: '{"select-centre":true,"show-open-order-filter":false,"date-range-plus":0,"table":[{"name":"Arrived","key":"arrived_consol_date","case":"name"},{"name":"Size Chart","type":"number","key":"size_chart_YN","case":"normal"},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal"},{"name":"Style","key":"style_code","case":"normal"},{"name":"TOT. QTY.","type":"number","key":"order_quantity","case":"normal"},{"name":"Comp.","key":"select_for_compliance","case":"check"},{"name":"AQL","key":"select_for_AQL","case":"check"},{"name":"Inspection Date","key":"inspection_date","case":"select_date"},{"name":"Supplier Name","key":"supplier_name","case":"name"},{"name":"PO Numbers","key":"client_pos","case":"normal","empty":true},{"name":"Action","case":"insp-action"}],"date-title":"Arrived Date","date-range-minus":1}',
  aqlPlan: '{"date-range-plus":0,"table":[{"name":"AQL","show":true,"key":"qcdata_select_for_AQL","case":"select_unselect"},{"name":"Insp. Date","show":true,"key":"inspection_date","case":"name"},{"name":"Loc.","show":true,"type":"number","key":"UDC_id","case":"normal"},{"name":"Insp.","show":true,"type":"number","key":"inspection_number","case":"normal"},{"name":"Supplier Name","show":true,"key":"supplier_name","case":"name"},{"name":"Season","show":true,"key":"season_code","case":"normal"},{"name":"PO Numbers","show":true,"key":"client_pos","case":"normal"},{"name":"STYLE","show":true,"key":"style_code","case":"normal"},{"name":"Description","show":true,"key":"style_name","case":"name"},{"name":"Colour","show":true,"key":"colour","case":"name"},{"name":"PO QTY.","show":true,"type":"number","key":"order_quantity","case":"normal"},{"name":"Level","show":true,"type":"number","key":"sample_level","case":"level"},{"name":"INSP. Qty.","show":true,"type":"number","key":"inspection_quantity","case":"normal"},{"name":"SUB.","show":false,"type":"number","key":"inspection_number","case":"normal"},{"name":"AQL Maj<","show":true,"type":"number","key":"allowed_defects_maj","case":"normal"},{"name":"AQL Min<","show":true,"type":"number","key":"allowed_defects_min","case":"normal"},{"name":"Tot. Defects","show":true,"type":"number","key":"count_defects","case":"normal"},{"name":"Current Status","show":true,"key":"status_EN","case":"normal"},{"name":"Result","show":true,"key":"result","case":"result"},{"name":"Inspector Name","show":true,"key":"Inspector","case":"normal"},{"name":"Customer","show":false,"key":"customer_name","case":"normal"}],"select-centre":true,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","show-inspection-type":false,"status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"title":"Style Code","field":"style_code"},{"title":"Style Name","field":"style_name"},{"title":"Colour","field":"colour"},{"title":"Supplier","field":"supplier_name"},{"title":"PO Number","field":"PO_Nos"},{"title":"Order Size","field":"Order_Size"},{"title":"Submission","field":"inspection_number"},{"title":"Inspection Level","field":"sample_level"},{"title":"Sample Qty.","field":"inspection_quantity"}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":true,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[{"name":"brand","url":"https://brand.cliv.info/"}]},
};